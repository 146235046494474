import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingscreenService } from '../services/loadingscreen.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  p;
  token;
  hide = true;
  resetformgroup:FormGroup;
  errorflag:boolean = false;
  errormessage:string = "";
  roletype;

  constructor(private route:ActivatedRoute,private http:HttpClient,private router:Router,private loadingscreenservice:LoadingscreenService) { 
    
     this.p = this.route.snapshot.queryParams["p"];
     this.token = this.route.snapshot.queryParams["token"];
     this.roletype = this.route.snapshot.queryParams["type"];
     
     this.resetformgroup = new FormGroup({
      password:new FormControl("",[Validators.required,Validators.minLength(6)]),
      repassword:new FormControl("",[Validators.required,Validators.minLength(6)]),
    });
  }


  ngOnInit() {
  }


  submit(){

    if (this.resetformgroup.controls["password"].status === 'INVALID' || this.resetformgroup.controls["repassword"].status === 'INVALID') {
      this.showErrorMessage("Password should contain atlease 6 characters");
      return;
    } else if (this.resetformgroup.controls["password"].value.trim().length === 0) {
      this.showErrorMessage("Enter a valid password ");
      return;
    } else if (this.resetformgroup.controls["password"].value != this.resetformgroup.controls["repassword"].value) {
      this.showErrorMessage("New password and confirmation password does not match.");
      return ;
    } else {
      this.http.post(environment.url + "api/auth/setPassword",{"id":this.p,"token":this.token,"password":this.resetformgroup.controls["password"].value.trim()}).subscribe(
      data => {
        if (!!data['success']) {
          this.router.navigate(['user/signup'], {
            queryParams:{componentType: 'login'}
          })
        } else {
          this.showErrorMessage(data["data"]);
          
        }
      },
      error => {   
        // this.loadingscreenservice.stopLoading();
        this.showErrorMessage(error);
        console.log(error);
      }
    );
    }

  }

  showErrorMessage(message){
    this.errorflag=true;
    this.errormessage = "*"+message;

  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      event.preventDefault();
      this.submit();
    }
  }

  goLandingPage(){
    this.router.navigate(['./user/']);
  }
}
