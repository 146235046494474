// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url:"https://e-affidavit.ng/data/",
  //url:"http://localhost:8088/",
  publicKey:"FLWPUBK_TEST-513b41e4eda890fd8d28a61481814fd0-X",
  //eversignServerUrl:"http://127.0.0.1:9000/",
  eversignServerUrl:"https://e-affidavit.ng/nv/",
  accessKey:'dce58999de1a67a6329d21550a746818',
  businessId:'678089',
  //publicKey:"FLWPUBK-120c907f57ada96c1d0594179580c368-X"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
