export class SignupRequestModel {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  userRole;
  isCorporate: boolean;
  corporateInfo: string;
  paymentDetailId: number;
  registrationPrice: number;
  mobile: number;
  isAgent: boolean;
  lasrra: number;
  cacNumber: number;
}
