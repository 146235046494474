import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { UserComponent } from "./user/user.component";
import { BusinessComponent } from "./business/business.component";
import { CourtComponent } from "./court/court.component";
import { PagenotfoundComponent } from "./shared/pagenotfound/pagenotfound.component";
import { VerificationComponent } from './verification/verification.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { GlobalLoginComponent } from './global-login/global-login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgotPassword/forgot.component';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/user/home",
    pathMatch: "full"
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then(m => m.UserModule)
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule)
  },
  // {
  //   path:'admin',
  //   children:[...adminroutes]
  // },
  {
    path: "business",
    component: BusinessComponent
  },
  {
    path: "court",
    loadChildren: () => import("./court/court.module").then(m => m.CourtModule)
  },
  {
    path:"api/auth/verify",
    component:VerificationComponent
  },
  {
    path:"api/auth/setPassword",
    component:ResetpasswordComponent
  },
  {
    path: '**',
     redirectTo: '/404'
  } ,
  {
    path: '404', 
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
