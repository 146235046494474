import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BusinessComponent } from "./business.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [BusinessComponent],
  imports: [CommonModule]
})
export class BusinessModule {}
